@tailwind base;
@tailwind components;
@tailwind utilities;

@property --num {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}

.test-animation {
  transition: all 0.3s ease-in-out 0.3s;
  height: 0;
  opacity: 0.8;
  max-height: 0;
  overflow: hidden;
}

.test-animation.active {
    transition: all 0.5s ease-in-out 0s !important;
    height: auto;
    opacity: 1;
    max-height: 1000px
}

@layer base {
  h1 {
    @apply text-3xl md:text-6xl font-medium;
  }

  h2 {
    @apply text-2xl font-medium md:text-4xl;
  }

  h3 {
    @apply text-3xl font-medium;
  }

  h4 {
    @apply text-2xl font-medium;
  }

  h5 {
    @apply text-xl font-medium;
  }

  h6 {
    @apply text-xl font-medium;
  }

  .body1 {
    @apply text-base font-normal;
  }

  .body2 {
    @apply text-sm font-normal;
  }

  .body2bold {
    @apply text-sm font-semibold;
  }

  .caption {
    @apply text-xs font-normal;
  }
}
